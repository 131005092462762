<template>
  <b-card class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="bg-light-primary rounded-top text-center cursor-pointer">
          <b-img
            :src="require('@/assets/images/illustration/email.svg')"
            alt="Meeting Pic"
            height="170"
          />
        </div>
      </div>
      <div class="col-md-12">
        <b-card-body>
          <!-- meeting header -->
          <div class="d-flex align-items-start justify-content-center">
            <h4 class="mb-0">
              {{
                new Date(training.tr_date).toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "long",
                })
              }}
            </h4>
            <h4 class="mb-0 ml-1">
              {{
                new Date(training.tr_date)
                  .toLocaleDateString("en-US", {
                    weekday: "long",
                  })
                  .toUpperCase()
              }}
            </h4>
          </div>
          <b-card-title class="my-2 text-center">
            {{ training.tr_heading }}
          </b-card-title>
          <div class="meetup-header">
            <b-row>
              <b-col cols="12" md="6">
                <div class="my-auto w-100">
                  <b-card-text
                    class="mb-0"
                    v-html="training.tr_description"
                  ></b-card-text>
                </div>
              </b-col>
              <b-col cols="12" md="6">
                <div class="ml-5">
                  <b-media no-body class="mt-2">
                    <b-media-aside class="">
                      <b-avatar rounded variant="light-primary" size="40">
                        <feather-icon icon="CalendarIcon" size="30" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h4 class="mb-0">
                        {{
                          new Date(training.tr_date).toLocaleDateString(
                            "en-US",
                            {
                              day: "numeric",
                              month: "short",
                              weekday: "short",
                            }
                          )
                        }}
                      </h4>
                      <!-- <h4>10:AM to 6:PM</h4> -->
                    </b-media-body>
                  </b-media>
                  <b-media no-body class="mt-2">
                    <b-media-aside class="mr-1">
                      <b-avatar rounded variant="light-primary" size="40">
                        <feather-icon icon="MapPinIcon" size="30" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body>
                      <h4 class="mb-0">Virtual</h4>
                      <a :href="training.tr_link" target="_blank">{{
                        training.tr_link
                      }}</a>
                    </b-media-body>
                  </b-media>
                </div>
              </b-col>
            </b-row>
          </div>
          <!--/ meeting header -->

          <!-- media -->

          <b-row class="mt-2">
            <b-col md="6">
              <h4 class="mb-2">Registered Users</h4>
              <div class="d-flex flex-wrap">
                <div
                  v-for="student in training.register_list"
                  :key="student.user_id"
                  class="d-flex align-items-center mr-1 mb-1"
                >
                  <b-avatar
                    :src="
                      student.user_image ||
                      require('@/assets/images/portrait/small/avatar-s-9.jpg')
                    "
                    class="pull-up"
                  />
                  <span class="ml-1">{{ student.user_fullname }}</span>
                </div>
              </div>
            </b-col>

            <b-col md="6">
              <h4 class="font-weight-bold">Material</h4>
              <div v-if="no_material && user_type === 'counselor'">
                <b-form>
                  <b-form-group>
                    <b-form-radio-group
                      v-model="material_type"
                      :options="material_options"
                    />
                  </b-form-group>
                  <b-form-group
                    v-if="material_type === 'youtube'"
                    label="Enter YouTube Link"
                  >
                    <b-form-input
                      type="url"
                      v-model="training_material.youtube_link"
                      placeholder="Enter YouTube Link"
                    />
                  </b-form-group>
                  <b-form-group
                    v-if="material_type === 'drive'"
                    label="Enter Google Drive Link"
                  >
                    <b-form-input
                      type="url"
                      v-model="training_material.file_link"
                      placeholder="Enter Google Drive Link"
                    />
                  </b-form-group>
                  <b-form-group
                    v-if="material_type === 'file'"
                    label="Upload Material"
                  >
                    <b-form-file
                      v-model="file_data"
                      accept=".pdf, .doc, .docx, .txt"
                      plain
                    />
                  </b-form-group>
                </b-form>
                <div>
                  <b-button variant="primary" size="sm" @click="addMaterial">
                    Add
                  </b-button>
                </div>
              </div>
             <div v-if="!no_material" class="mt-2">
              <div v-if="!file_data_is_there">
                <a :href="training_material_data" target="_blank" class="d-flex align-items-center"><feather-icon icon="FileTextIcon" size="25" class="text-success" /><span class="ml-1 font-size-1-5 font-weight-bold">Presentation Document</span></a>
              </div>
              <div v-if="file_data_is_there">
                <a :href="FILESURL + training_material_data" target="_blank"
                class="d-flex align-items-center"
                  ><feather-icon icon="FileTextIcon" size="25" class="text-success" /><span class="ml-1 font-size-1-5 font-weight-bold">Presentation Document</span></a
                >
              </div>
             </div>
            </b-col>
          </b-row>
        </b-card-body>
      </div>
    </div>
  </b-card>
</template>

<script>
import CommonServices from "@/apiServices/CommonServices";
import { TokenService } from "@/apiServices/storageService";
import { BASEURL, FILESURL } from "@/config";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import {
  BCard,
  BImg,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BButton,
  BCardText,
  BCardTitle,
  BRow,
  BCol,
  BFormGroup,
  BFormFile,
  BForm,
  BFormRadioGroup,
  BFormInput,
} from "bootstrap-vue";
import axios from "axios";
import store from "@/store";

export default {
  name: "TrainingDetails",
  components: {
    BCard,
    BImg,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BButton,
    BCardText,
    BCardTitle,
    BRow,
    BCol,
    BFormGroup,
    BFormFile,
    BForm,
    BFormRadioGroup,
    BFormInput,
  },
  data() {
    return {
      training: {},
      type: "", // add type data property if needed
      material_type: "",
      material_options: [
        { text: "Youtube", value: "youtube" },
        { text: "Google Drive", value: "drive" },
        { text: "File", value: "file" },
      ],
      youtube_link: "",
      drive_link: "",
      uploaded_file: null,
      training_material: {
        name: " ",
        trm_order: 1,
        youtube_link: null,
        file_link: null,
        uploaded_file_link: null,
      },
      file_data: null,
      training_material_data: null,
      tr_id: null,
      no_material: false,
      file_data_is_there: false,
      FILESURL,
    };
  },
  methods: {
    async getTraining(trainingId) {
      try {
        const res = await CommonServices.getTrainingById({ tr_id: trainingId });
        if (res.data.status) {
          this.training = res.data.data;
        }
      } catch (error) {
        console.error("Error in getTraining", error);
      }
    },

    async getTrainingMaterials(trainingId) {
      try {
        const res = await CommonServices.getTrainingMaterials({
          tr_id: trainingId,
        });
        if (res.data.status) {
          if (res.data.data.length === 0) {
            this.no_material = true;
          }
          const material = res.data.data[0];
          if (material.youtube_link !== null) {
            this.training_material_data = material.youtube_link;
          } else if (material.file_link !== null) {
            this.training_material_data = material.file_link;
          } else if (material.uploaded_file !== null) {
            this.training_material_data = material.uploaded_file;
            this.file_data_is_there = true;
          }
        }
      } catch (error) {}
    },
    async addMaterial() {
      try {
        if (this.material_type === "file") {
          const AUTH_TOKEN = TokenService.getToken();

          const formData = new FormData();
          formData.append("material", this.file_data);

          const response = await axios.post(
            `${BASEURL}/common/training-materials-upload-file`,
            formData,
            {
              headers: {
                "content-type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                authorization: AUTH_TOKEN,
              },
            }
          );

          if (response.data.status) {
            console.log(response);
            this.training_material.uploaded_file_link =
              response.data.data.file_path;

            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: "Document Uploaded Successfully",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.getTrainingMaterials(this.tr_id);
          } else {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: response.data.message || "Document Uploaded Failed",
                icon: "EditIcon",
                variant: "failure",
              },
            });
          }
        }

        //post training
        const res = await CommonServices.addTrainingMaterial({
          training_material: this.training_material,
          tr_id: this.tr_id,
        });

        if (res.data.status) {
          // console.log(res);
          // this.resetTrainingMaterial();
          this.getTrainingMaterials(this.tr_id);
        }
      } catch (error) {
        console.error("Error in addMaterial", error);
      }
    },
    resetTrainingMaterial() {
      this.training_material = {
        name: " ",
        trm_order: 1,
        youtube_link: null,
        file_link: null,
        uploaded_file: null,
      };
    },
  },
  computed: {
    user_type() {
      return this.$store.getters["user/getUserDetails"].user_type;
    },
  },
  beforeMount() {
    const trainingId = this.$route.query.id;
    this.tr_id = trainingId;
    this.getTraining(trainingId);
    this.getTrainingMaterials(trainingId);
  },
};
</script>
<style>
.font-size-1-5{
  font-size: 1.5rem;
}
</style>